.mainButton {
    background-color: black;
    color: white;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 90%;
    border: 2px solid black;
}

.mainButton:hover {
    opacity: 0.8;
}

.mainButtonHollow {
    border: 2px solid black;
    background-color: transparent;
    color: black;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 90%;
}

.mainButtonHollow:hover {
    opacity: 0.6;
}

.whiteButton {
    background-color: white;
    color: black;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 80%;
}