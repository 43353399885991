.slider {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 10000000000000000000;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.slide-in {
    animation: slide-in 0.25s forwards;
    -webkit-animation: slide-in 0.25s forwards;
}

.slide-out {
    animation: slide-out 0.25s forwards;
    -webkit-animation: slide-out 0.25s forwards;
}

@keyframes slide-in {
    0% {
        transform: translateX(-110%);
    }

    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(-110%);
    }

    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-110%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-110%);
    }
}

.fade-effect {
    transition: opacity 0.5s ease-in-out;
  }